import React, { useEffect, useState } from "react";
import jsonData from "../services/data.json";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import DataListInput from "react-datalist-input";
import { v4 } from "uuid";
import "../styles/App.css";
import Select from 'react-select';

const SearchFilters = ({ selectExamCallback, position, isDisabled, selectedFilterCallback, selectedAddressCallback, selectedPrevicionCallback }) => {

    const [examsList, setExamList] = useState();

    useEffect(() => {
        const examsNames = [];
        jsonData.exams.forEach((exam) => {
            exam.names.forEach((name) => examsNames.push({
                label: name,
                key: v4(),
                ...exam
            }));
        });
        setExamList(examsNames);
    }, []);

    const onExamSelect = (selectedItem) => {
        selectExamCallback(selectedItem, position);
    }

    return (
        <div className="row g-0">
            <div className="dropdown-input pe-md-2 col-12 col-sm-3 mb-2">
                <DataListInput
                    placeholder="Busca tus exámenes"
                    inputClassName="data-list-input"
                    items={examsList}
                    onSelect={onExamSelect}
                    dropDownLength={5}
                />
            </div>
            {!isDisabled && (
                <>
                    <div className="dropdown-input pe-md-2 col-12 col-sm-3 mb-2">
                        <GooglePlacesAutocomplete
                            autocompletionRequest={{ componentRestrictions: { country: ["cl"] } }}
                            apiOptions={{ language: "es-419", region: 'cl' }}
                            selectProps={{
                                styles: {
                                    input: (provided) => ({
                                        ...provided,
                                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                                        opacity: 1,
                                        font: "normal normal normal 18px/25px",
                                        letterSpacing: "0px",
                                        color: "#505050",
                                    })
                                },
                                placeholder: "Ingrese dirección o comuna",
                                onChange: selectedAddressCallback ? selectedAddressCallback : undefined,
                                noOptionsMessage: () => null
                            }} />
                    </div>
                    <div className="dropdown-input select pe-md-2 col-12 col-sm-3 mb-2">
                        <Select onChange={selectedPrevicionCallback ? selectedPrevicionCallback : null} options={[{ value: "FONASA", label: "FONASA" }, { value: "ISAPRE", label: "ISAPRE" }, { value: "PARTICULAR", label: "PARTICULAR" }]} placeholder="Previsión" />
                    </div>
                    <div className="dropdown-input select col-12 col-sm-3 mb-2">
                        <Select onChange={selectedFilterCallback ? selectedFilterCallback : null} options={[{ value: "closer", label: "Cercanía" }, { value: "cheaper", label: "Más barato" }, { value: "medics", label: "Valoración médicos" }, { value: "patiens", label: "Valoración pacientes" }]} placeholder="Ordenar por" />
                    </div>
                </>)}

        </div>
    )
}

export default SearchFilters;
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const PaginatorControls = ({ leftCallback, rightCalback, maxItems, start, finish }) => {

    return (
        <div className="pagination-control">
            <FontAwesomeIcon onClick={leftCallback} icon={faArrowLeft} color="#0dcaf0" size="1x" />
            <p>{start} - {finish} de {maxItems}</p>
            <FontAwesomeIcon onClick={rightCalback} icon={faArrowRight} color="#0dcaf0" size="1x" />
        </div>
    )
}

export default PaginatorControls;
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from "./pages/home";
import "./assets/fonts/AvenirLTStd-Roman.otf";
import Resultados from "./pages/resultados";
import { Helmet } from "react-helmet";
import { v4 } from "uuid";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useJsApiLoader } from "@react-google-maps/api";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from "@fortawesome/free-regular-svg-icons";
import TagManager from 'react-gtm-module'

export default function App() {
  library.add(fab, far)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyA5P88OkhA1Y7LOAnLyrtqUY_3ua5Xt224",
    libraries: ["places", "visualization"]
  })
  const tagManagerArgs = {
    gtmId: 'GTM-WPBWDTK'
  }
  TagManager.initialize(tagManagerArgs)
  return isLoaded ? (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/*<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous" />*/}
        <link href="https://fonts.cdnfonts.com/css/avenir-lt-std" rel="stylesheet" />
        <title>Diagnostic</title>
      </Helmet>
      <Switch>
        <Route path="/resultados" render={(props) => <Resultados {...props} key={v4()} />}>
        </Route>
        <Route path="/">
          <Home key={v4()} />
        </Route>
      </Switch>
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>
    </Router>
  ) : <></>
}

import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/image/diagnosticmenu.svg";
import "../styles/App.css";

const Header = () => {
	return (
		<header>
			<div className="container-fluid g-0 justify-content-center p-2">
				<Link to={'/'}>
					<img width="350" height="55" src={logo} alt="logo" />
				</Link>
			</div>
		</header>
	)
}

export default Header;
import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import "../styles/map-container.css";
import bluePin from "../assets/image/hospital-seleccionado.svg";
import selectedPin from "../assets/image/pinhospital.svg";
import { isBrowser, isMobile } from "react-device-detect";
import iconlocal from "../assets/image/iconlocal.svg";
import star from "../assets/image/estrella.svg";
import money from "../assets/image/money.svg";
import jsonData from "../services/data.json";
import NumberFormat from "react-number-format";
import { useRef } from "react";
import diagnosticmenu from "../assets/image/diagnosticmenu.svg";

export const MapContainer = ({ mapsItems, lastHover, pinClickCallback, selectedExams }) => {
	const [userLocation, setUserLocation] = useState({ lat: -33.45694, lng: -70.64827 });
	const [zoom, setZoom] = useState(14);
	const [map, setMap] = useState(null)
	const mapRef = useRef(null);

	const onLoad = useCallback((map) => {
		const bounds = new window.google.maps.LatLngBounds();
		map.fitBounds(bounds);
		setMap(map)
	}, [])

	const onUnmount = useCallback((map) => {
		setMap(null)
	}, [])

	useEffect(() => {
		if (mapsItems.length > 0) {
			const { latLng } = mapsItems[mapsItems.length - 1];
			setTimeout(() => {
				setUserLocation({ lat: latLng[0], lng: latLng[1] });
				setZoom(13);
			}, 200);
		}
	}, [mapsItems])

	useEffect(() => {
		handleHover();
	}, [lastHover]);

	const handleHover = () => {
		if (lastHover && mapsItems && mapsItems.length > 0) {
			const item = mapsItems.find((item) => item.id === lastHover);
			if (item) {
				const { latLng } = item
				setUserLocation({ lat: latLng[0], lng: latLng[1] });
				setZoom(13);
			}
		}
	}

	useEffect(() => {
		getUserLocation();
	}, [])

	const getUserLocation = async () => {
		const { geolocation } = navigator;
		if (geolocation) {
			const { state } = await navigator.permissions.query({ name: "geolocation" });
			if (state === "granted" || state === "prompt") {
				geolocation.getCurrentPosition(successGeolocation, errorGeolocation)
			}
		} else {
			console.log("Gelocación no disponible");
		}
	}

	const successGeolocation = (position) => {
		//const { latitude, longitude } = position.coords;
		//setUserLocation({ lat: latitude, lng: longitude });
	}

	const errorGeolocation = (error) => {
		console.log(`error gelocation ${error}`);
	}
	return (
		<GoogleMap
			ref={mapRef}
			options={{
				zoomControlOptions: {
					position: 3
				},
				mapTypeControl: false,
				streetViewControl: false
			}}
			mapContainerStyle={{ position: "inherit", height: "100%" }}
			zoom={zoom}
			defaultZoom={13}
			defaultCenter={userLocation}
			defaultOptions={{
				scrollwheel: false,
				mapTypeControl: false,
				fullscreenControl: false,
				streetViewControl: false
			}}
			onLoad={onLoad}
			onUnmount={onUnmount}
			center={userLocation}
		>
			{mapsItems.map(({ latLng, id, center }) => {
				return (
					<Pin selectedExams={selectedExams} center={center} latLng={latLng} id={id} lastHover={lastHover} pinClickCallback={pinClickCallback} />
				)
			})}
		</GoogleMap>
	)
}

const Pin = ({ latLng, id, lastHover, pinClickCallback, center, selectedExams }) => {

	const [pin, setPin] = useState(bluePin);
	const [total, setTotal] = useState(0);

	const calculateTotal = () => {
		let totalValue = 0;
		const examsElements = selectedExams.map((exam) => {
			const e = jsonData.exams.find(({ id }) => exam.id === id);
			totalValue = totalValue + e.values[center.nConvenio - 1].copay;
		});
		setTotal(totalValue);
		return examsElements;
	}

	useEffect(() => {
		if (isBrowser) {
			if (id === lastHover) {
				setPin(selectedPin);
			} else {
				setPin(bluePin)
			}
		}
	}, [lastHover]);

	useEffect(() => {
		calculateTotal();
	}, [id]);

	return (
		<Marker onClick={() => pinClickCallback(id)} position={{ lat: latLng[0], lng: latLng[1] }} icon={lastHover === id ? selectedPin : pin} >
			{
				(id === lastHover && !isMobile) && (
					<InfoWindow>
						<div className="container info-window">
							<div className="row w-100">
								<div className="col-5 col-md-5 d-flex align-items-start">
									<img defaultSource={diagnosticmenu} className="img-fluid mt-3" src={(center.logo === undefined || center.logo.split(':')[0] === 'http') ? diagnosticmenu : center.logo} alt="logo" />
								</div>
								<div className="col-7 col-md-7">
									<div className="row mt-1">
										<div className="col-md-12 m-0 p-0">
											<p className="p-0 mb-1 m-0 mb-2" style={{fontSize:14}}><strong className="bolder">{center.name}</strong></p>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12 m-0 p-0 d-flex">
											<img src={iconlocal} alt="iconlocal" className="icon" />
											<p className="p-0 mb-2 m-0 gray">{center.address}</p>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12 m-0 p-0 d-flex">
											<img src={star} alt="iconlocal" className="icon" />
											<p className="p-0 mb-1 m-0 gray">Pacientes <b>{center.valoration.patiens}</b></p>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12 m-0 p-0 d-flex">
											<img src={star} alt="iconlocal" className="icon" />
											<p className="p-0 mb-1 m-0 gray">Médicos <b>{center.valoration.medics}</b></p>
										</div>
									</div>
									<div className="row mt-1">
										<div className="col-md-12 m-0 p-0 d-flex">
											<img src={money} alt="iconlocal" className="icon" />
											<p className="p-0 m-0 total"><strong>Total </strong>{<NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'$ '} />}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</InfoWindow>
				)
			}
		</Marker>
	)
}

export default MapContainer;
import React, { forwardRef, useEffect, useState } from "react";
import "../styles/card-exam.css";
import direccionSvg from "../assets/image/direccion.svg";
import valoracionSvg from "../assets/image/valoracion.svg";
import estrellaSvg from "../assets/image/estrella.svg";
import NumberFormat from 'react-number-format';
import "../styles/App.css";
import { isBrowser } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Button, Tooltip, Whisper } from "rsuite";
import diagnosticmenu from "../assets/image/diagnosticmenu.svg";

const CardExam = ({ id, isLastHover, hoverCallback, center, total, distance, onCompareChange, exams, index, prev }, ref) => {
	const [centerTotal, setCenterTotal] = useState(0);
	const [statusCheck, setStatusCheck] = useState({});

	useEffect(() => {
		setAmountTotal();
	}, []);

	const setAmountTotal = (i) => {
		let suma = 0;
		exams.map((e, i) => (
			suma += e.values[center.nConvenio - 1].copay
		));
		setCenterTotal(suma);
	}
	const handleTotalChange = (evt, price) => {
		evt.preventDefault()
		let status = {};
		status[evt.target.id] = evt.target.checked;
		Object.keys(statusCheck).length === 0 ? setStatusCheck(status) : setStatusCheck({ ...statusCheck, ...status })
		const _total = status[evt.target.id] ? centerTotal + price : centerTotal - price;
		setCenterTotal(_total);
	}

	const RenderExam = () => {
		const examsElements = exams.map((e, i) => (
			<>
				<div key={i} className="col-7 d-flex border-end pt-1 pb-1">
					<div className="form-check ps-0">
						<input type="checkbox" className="form-check-input form-check-input-blue mr-2"
							onClick={(x) => {
								handleTotalChange(x, e.values[center.nConvenio - 1].copay)
							}}
							id={`checkbox_${id}_${e.codeFonasa}_${i}`}
							name={`checkbox_${id}_${e.codeFonasa}_${i}`}
							checked={statusCheck[`checkbox_${id}_${e.codeFonasa}_${i}`]}
							defaultChecked={true}
						/>
						<label className="form-check-label">{e.label}</label>
					</div>
				</div>
				<div className="col-5 align-self-center">
					<p style={{ color: "#0A46A5" }} className="text-end text-md-start m-md-0 mb-0"><strong className="bolder">
						<NumberFormat value={e.values[center.nConvenio - 1].copay} displayType={'text'} thousandSeparator={true} prefix={'$ '} /></strong>
					</p>
				</div>
			</>
		));
		return examsElements;
	}


	const getPrevMessage = () => {
		switch (prev) {
			case "FONASA":
				return "Solo B-C-D. Pacientes FONASA A califican como PARTICULARES.";
			case "ISAPRE":
				return "Valores referenciales FONASA. Escríbenos a paroradi@gmail.com para solicitar tu plan de Isapre.";
			case "PARTICULAR":
				return "Solo pacientes FONASA A y sin previsión.";
			default:
				break;
		}
	}

	return (
		<div
			id={id}
			ref={ref}
			onMouseOver={() =>
				hoverCallback(id)
			}
			onClick={() => hoverCallback(id)}
			className={`card px-4 m-2 my-md-3 pt-2 pb-1 ${isLastHover ? 'border-info	' : ''}`}>
			<div className="row">
				<div className="col-md-12">
					<div className="row justify-content-center align-items-center border-bottom">
						<div className="col-5 col-md-3 border-end p-2">
							<img className="img-fluid logo-card" src={(center.logo === undefined || center.logo.split(':')[0] === 'http') ? diagnosticmenu : center.logo} alt="logo" />
						</div>
						<div className="col-7 col-md-5 text-center text-md-start px-2">
							<p className="m-0"><strong className="bolder">{center.localName}</strong></p>
						</div>
						<div className="col-7 col-md-4 text-end d-none d-md-block">
							<div className="form-check">
								<input id={id} onChange={onCompareChange} type="checkbox" className="form-check-input checkbox-modal" />
								<label className="form-check-label form-check-label-card">Comparar</label>
							</div>
						</div>
					</div>
					<div className="row justify-content-center align-items-center d-md-none border-bottom">
						<div className="col-5 border-end">
							<p></p>
						</div>
						<div className="col-7 d-flex justify-content-center">
							<div className="form-check py-2">
								<input id={id} onChange={onCompareChange} type="checkbox" className="form-check-input" />
								<label className="form-check-label">Comparar</label>
							</div>
						</div>
					</div>
					<div className="row justify-content-center align-items-center border-bottom py-md-2">
						<div className="col-5 col-md-3 d-flex border-end align-items-end p-1">
							<img src={direccionSvg} alt="dirección" className="icon-card" />
							<p className="mb-0">Distancia</p>
						</div>
						<div className="col-7 col-md-9 d-flex justify-content-between px-2">
							<p className="mb-0 align-self-center">{Intl.NumberFormat("es", { maximumFractionDigits: 0 }).format(distance)} km</p>
							<p className="mb-0 align-self-center">{center.address}</p>
						</div>
					</div>
					<div className="row justify-content-center align-items-center border-bottom py-md-2">
						<div className="col-5 col-md-3 d-flex border-end p-1">
							<img className="valoration-icon icon-card" src={valoracionSvg} alt="valoración" />
							<p className="m-0 p-0 align-self-center">Valoración</p>
						</div>
						<div className="d-none col-7 col-md-9 d-md-flex justify-content-between px-2">
							<div className="d-flex col-md-6">
								<p className="p-0 m-0 align-self-center">Pacientes</p>
								<img style={{ width: "fit-content" }} src={estrellaSvg} alt="estrella" className="start" />
								<p className="p-0 m-0 align-self-center"><b>{5 - ((index * 2) / 10)}</b> <span className="votos">({70 - index})</span></p>
							</div>
							<div className="d-flex col-md-6">
								<p className="p-0 m-0 align-self-center">Médicos</p>
								<img style={{ width: "fit-content" }} src={estrellaSvg} alt="estrella" className="start" />
								<p className="p-0 m-0 align-self-center"><b>{5 - ((index * 2) / 10)}</b> <span className="votos">({70 - index})</span></p>
							</div>
						</div>
						<div className="d-flex col-7 col-md-9 d-md-none justify-content-between">
							<div className="row">
								<div className="col-12 d-flex justify-content-between pt-1 pe-2">
									<p className="m-0 p-0">Pacientes</p>
									<div className="d-flex">
										<img src={estrellaSvg} alt="estrella" className="start" />
										<p className="p-0 mx-1 m-0"><b>{5 - ((index * 2) / 10)}</b> <span className="votos">({70 - index})</span></p>
									</div>
								</div>
								<div className="col-12 d-flex justify-content-between pt-1 pe-2">
									<p className="m-0 p-0">Médicos</p>
									<div className="d-flex">
										<img src={estrellaSvg} alt="estrella" className="start" />
										<p className="p-0 mx-1 m-0"><b>{5 - ((index * 2) / 10)}</b> <span className="votos">({70 - index})</span></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row card-content mt-2">
				<div className="col-md-8 pe-md-5 px-2">
					<div className="row justify-content-center align-items-center border-bottom">
						<div className="col-7 col-md-6">
							<p className="mb-1"><b>Tu búsqueda</b></p>
						</div>
						<div className="col-5 col-md-6 text-last-center">
							<p className="text-end text-md-start mb-0" style={isBrowser ? {
								float: "left",
								marginLeft: 10,
								marginRight: 10,
								marginBottom: 0,
								display: 'inline-block'
							} : {
								marginRight: 10,
								display: 'inline-block'
							}}>{prev}</p>
							<Whisper
								trigger="click"
								placement="topEnd"
								speaker={
									<Tooltip>
										{getPrevMessage()}
									</Tooltip>
								}
							>
								<Button appearance="subtle"
									style={{
										padding: 0 + "px",
										display: 'inline-block'
									}}
								>
									<FontAwesomeIcon icon={faQuestionCircle} />
								</Button>
							</Whisper>
							<i className="fa-light fa-circle-question"></i>
						</div>
					</div>
					<div className="row justify-content-center align-items-center border-bottom">
						<RenderExam />
					</div>
					<div className="row justify-content-center align-items-center py-2">
						<div className="col-7">
							<p className="m-0"><b className="bolder">Total</b></p>
						</div>
						<div className="col-5">
							<p style={{ color: "#0A46A5", marginRight: 4 }} className="text-end text-md-start mb-0">
								<strong className="bolder"><NumberFormat value={centerTotal} displayType={'text'} thousandSeparator={true} prefix={'$ '} /></strong>
							</p>
						</div>
					</div>
				</div>
				<div className="col-md-4 p-2 p-md-0 align-self-center">
					<a href={center.link} target="_blank" className="btn pay-button w-100 p-2" rel="noreferrer">Buscar horas y pagar</a>
				</div>
			</div>
		</div>
	)
}

export default forwardRef(CardExam);

import React, { useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import "../styles/home.css";
import "../styles/App.css";
import banner from "../assets/image/bannerprincipal.png";
import bannerMobile from "../assets/image/banner-mobile.png";
import mas from "../assets/image/mas.svg";
import SearchFilters from "../components/search-filters";
import { useHistory } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import starMobile from "../assets/image/estrella-home-mobile.svg";
import star from "../assets/image/estrellaescritorio.svg";
import hospital from "../assets/image/hospital-escritorio.svg";
import hospitalMobile from "../assets/image/hospital-home-mobile.svg";
import lupaMobile from "../assets/image/lupa-mobile.svg";
import lupa from "../assets/image/lupaescritorio.svg";
import Whatsapp from "../components/whatsapp";
import toaster from "rsuite/toaster";
import Message from "rsuite/Message"
import "rsuite/dist/rsuite.min.css";

export default function Home() {

	return (
		<div className="container-fluid g-0">
			<Header />
			<main>
				<MainContent />
			</main>
			<Footer />
		</div>
	)
}

const MainContent = () => {
	const [examNumber, setExamNumber] = useState([0]);
	const [selectedExams, setSelectedExams] = useState([]);
	const [selectedAddress, setSelectedAddress] = useState();
	const [selectedFilter, setSelectedFilter] = useState();
	const [selectedPrevicion, setSelectedPrevicion] = useState();
	const history = useHistory();

	const selectExamCallback = (exam, position) => {
		const examsArray = selectedExams;
		examsArray[position] = exam;
		setSelectedExams([...examsArray]);
	}

	const addExam = () => {
		let n = examNumber.length;
		n++;
		setExamNumber(new Array(n).fill(0));
	}

	const goToResults = () => {
		if (selectedExams.length > 0 && selectedPrevicion !== undefined) {
			history.push('/resultados', { exams: selectedExams, filter: selectedFilter, address: selectedAddress, prev: selectedPrevicion.value });
		} else {
			toaster.push(
				<Message type="error">
					{selectedExams.length === 0 ? "Escoja al menos un examen" : "Seleccione su previsión"}
				</Message>
			)
		}
	};

	return (
		<div className="container-fluid image-fondo">
			<div className="row mx-md-5 mx-4 py-5">
				<div className="col-12 col-md-4 mb-2 text-white d-flex flex-row flex-md-column align-items-center">
					<img src={isMobile ? lupaMobile : lupa} alt="mas" className="img-fluid me-2 home-icons" />
					<p className="text-md-center px-md-6 mt-2 mt-md-3 mb-md-5 mb-0 home-text"><strong>Busca</strong> los exámenes que necesitas.</p>
				</div>
				<div className="col-12 col-md-4 mb-2 px-md-6 text-white d-flex flex-row flex-md-column align-items-center">
					<img src={isMobile ? hospitalMobile : hospital} alt="mas" className="img-fluid me-2 home-icons" />
					<p className="text-md-center mt-2 mt-md-3 mb-md-5 home-text"><strong>Compara</strong> miles de centros de salud en un solo lugar.</p>
				</div>
				<div className="col-12 col-md-4 mb-2 text-white d-flex flex-row flex-md-column align-items-center">
					<img src={isMobile ? starMobile : star} alt="mas" className="img-fluid me-2 home-icons" />
					<p className="text-md-center px-md-6 mt-2 mt-md-3 mb-md-5 mb-0 home-text"><strong>Elige</strong> el mejor según precio, calidad y cercanía.</p>
				</div>
				<div className="col-12 mt-5 filters-container">
					{examNumber.map((n, i) => (<div className="col-12">
						<SearchFilters
							isDisabled={i > 0}
							position={i}
							selectExamCallback={selectExamCallback}
							selectedFilterCallback={i === 0 ? setSelectedFilter : undefined}
							selectedAddressCallback={i === 0 ? setSelectedAddress : undefined}
							selectedPrevicionCallback={i === 0 ? setSelectedPrevicion : undefined}
						/>
					</div>))}
				</div>
				<div className="col-12 col-md-3 mt-2 mt-md-1">
					<button className="btn btn-transparent px-0" onClick={addExam}>
						<img src={mas} alt="banner" style={{ pointerEvents: "all", width: 22 }} />
						<span style={{ color: "white", marginLeft: 10, fontWeight: 200 }}>Agregar exámenes</span>
					</button>
				</div>
				<div className="col-12 offset-md-6 col-md-3 mt-2 mt-md-1">
					<button style={{ backgroundColor: "#2EC3E5", color: "white" }} onClick={goToResults} className="submit-button m-16 mt-2">Buscar</button>
				</div>
			</div>
			<Whatsapp />
		</div>
	)
}
import React, { useEffect, useState } from "react";
import estrellaSvg from "../assets/image/estrella.svg";
import NumberFormat from "react-number-format";
import * as PubSub from "pubsub-js";
import diagnosticmenu from "../assets/image/diagnosticmenu.svg";

const ItemComparador = ({ i, index, value, removeCompareItem, nConvenio }) => {
    const [centerTotal, setCenterTotal] = useState(0);
    const [statusCheck, setStatusCheck] = useState({});
    useEffect(() => {
        if (i === 4) {
            PubSub.subscribe("CENTER_TOTAL", (message, data) => {
                if (data.index === index) {
                    setCenterTotal(data.total);
                }
            });
        }
        setAmountTotal();
    }, []);

    const setAmountTotal = (i) => {
        let suma = 0;
        value.e.map((e, i) => (
            suma += e.values[nConvenio - 1].copay
        ));
        setCenterTotal(suma);
    }
    const handleTotalChange = (evt, price) => {
        evt.preventDefault()
        let status = {};
        status[evt.target.id] = evt.target.checked;
        Object.keys(statusCheck).length === 0 ? setStatusCheck(status) : setStatusCheck({ ...statusCheck, ...status })
        const _total = status[evt.target.id] ? centerTotal + price : centerTotal - price;
        PubSub.publish("CENTER_TOTAL", { total: _total, index });
        setCenterTotal(_total);
    }
    const RenderExam = () => {
        const examsElements = value.e.map((e, i) => (
            <div className="row">
                <div className="col-12 col-md-8 text-xs-start">
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input mr-2"
                            onClick={(x) => {
                                handleTotalChange(x, e.values[nConvenio - 1].copay)
                            }}
                            id={`checkbox_${index}_${e.codeFonasa}_${i}`}
                            name={`checkbox_${index}_${e.codeFonasa}_${i}`}
                            checked={statusCheck[`checkbox_${index}_${e.codeFonasa}_${i}`]}
                            defaultChecked={true}
                        />
                        <label className="form-check-label">{e.name}</label>
                    </div>
                </div>
                <div className="col-12 col-md-4 text-xs-end">
                    <p style={{ color: "#0A46A5" }} className="text-end text-md-start m-md-0 mb-0"><strong className="bolder">
                        <NumberFormat value={e.values[nConvenio - 1].copay} displayType={'text'} thousandSeparator={true} prefix={'$ '} /></strong>
                    </p>
                </div>
            </div>
        ));
        return examsElements;
    }
    return (
        <>
            {i === 0 && (
                <td className="text-center">
                    <div className="logo-remove">
                        <div className="logo">
                            <img defaultSource={diagnosticmenu} src={(value.center.logo === undefined || value.center.logo.split(':')[0] === 'http') ? diagnosticmenu : value.center.logo} alt="" className="img-fluid logo-card" />
                        </div>
                        <div className="remove">
                            <span onClick={() => removeCompareItem(value)} className="compare-item-remove">x</span>
                        </div>
                    </div>
                </td>
            )}
            {i === 1 && (
                <td className="text-center">
                    <p>{Intl.NumberFormat("es", { maximumFractionDigits: 0 }).format(value.distance)} km</p>
                </td>
            )}
            {i === 2 && (
                <td className="text-center p-2">
                    <div className="d-flex m-0 p-0">
                        <div className="col-6">
                            <p className="m-0 text-end">Pacientes</p>
                        </div>
                        <img src={estrellaSvg} alt="estrella" className="start" />
                        <p className="m-0"><b>{5 - ((index * 2) / 10)}</b> <span className="votos">({70 - index})</span></p>
                    </div>
                    <div className="d-flex m-0 p-0">
                        <div className="col-6">
                            <p className="m-0 text-end">Médicos</p>
                        </div>
                        <img src={estrellaSvg} alt="estrella" className="start" />
                        <p className="m-0"><b>{5 - ((index * 2) / 10)}</b> <span className="votos">({70 - index})</span></p>
                    </div>
                </td>
            )}
            {i === 3 && (
                <td>
                    <RenderExam />
                </td>
            )}
            {i === 4 && (
                <td className="text-center">
                    <NumberFormat value={centerTotal} displayType={'text'} thousandSeparator={true} prefix={'$ '} />
                </td>
            )}
            {i === 5 && (
                <td className="text-center p-2">
                    <a href={value.center.link} target="_blank" className="btn btn-primary pay-button w-100 p-2" rel="noreferrer">Buscar hora y pagar</a>
                </td>
            )}
        </>
    )
}

export default ItemComparador;
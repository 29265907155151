import React from "react";
import whts from "../assets/image/whatsapp.svg";

const Whatsapp = () => {

    const openWhatsapp = () => {
        window.open("https://wa.me/56991398995", "_blank");
    }

    return (
        <div onClick={openWhatsapp} className="whatsapp-fixed justify-content-center align-items-center">
            <img width="60" height="60" src={whts} alt="banner" className="my-3 me-2" />
        </div>
    )
}

export default Whatsapp;

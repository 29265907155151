export const haversineDistance = (coords1, coords2, isMiles) => {
    function toRad(x) {
        return x * Math.PI / 180;
    }

    var lon1 = coords1[0];
    var lat1 = coords1[1];

    var lon2 = coords2[0];
    var lat2 = coords2[1];

    var R = 6371; // km

    var x1 = lat2 - lat1;
    var dLat = toRad(x1);
    var x2 = lon2 - lon1;
    var dLon = toRad(x2)
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    if (isMiles) d /= 1.60934;

    return d;
}

export const paginate = (array, page_size, page_number) => (array.slice((page_number - 1) * page_size, page_number * page_size))

const regions = ["Chile","Región de Tarapacá","Región de Antofagasta","Región de Atacama","Región de Coquimbo","Región de Valparaíso","Región del Libertador General Bernardo O’Higgins","Región del Maule","Región del Biobío"," Región de La Araucanía","Región de Los Lagos","Región Aysén del General Carlos Ibáñez del Campo","Región de Magallanes y Antártica Chilena","Región Metropolitana", "Región de Los Ríos", "Región de Arica y Parinacota", "Región de Ñuble"];

export const getRegionName = (num) => regions[num];
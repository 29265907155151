import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/image/diagnosticmenu.svg";
import "../styles/App.css";
import "../styles/footer.css";

const Footer = () => {
	let hidden = window.location.pathname === '/resultados' ? 'd-md-none' : '';
	return (
		<footer className="g-0">
			<div className="container-fluid d-flex">
				<div className="row container-fluid m-0 p-0">
					<div className={`col-12 ${hidden}`} style={{ backgroundColor: "#F4F4F4" }}>
						<Link to={'/'}>
							<img className="col-12 my-2 my-md-3" width="200" height="50" src={logo} alt="logo" />
						</Link>
						<p className="d-block d-sm-none text-center mt-2 mb-1 text-green">Déjanos tu opinión y ayúdanos a mejorar </p>
						<p className="col-12 text-2xl text-center">Contacto <a href="mailto:paroradi@gmail.com" className="inline-mail">paroradi@gmail.com</a></p>
					</div>
					<div style={{ backgroundColor: "#FAFAFA" }} className="col-12 d-flex text-center pt-2">
						<p className="mt-2 mb-3 col-12 text-base copyright-text">Diagnostic © Todos los derechos reservados Copyrights 2021.  Desarrollado por GUX</p>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;
import React, { useState, useEffect } from "react";
import Header from "../components/header";
import MapContainer from "../components/map-container";
import Footer from "../components/footer";
import "../styles/App.css";
import "../styles/resultados.css";
import SearchFilters from "../components/search-filters";
import CardExam from "../components/card-exam";
import { useHistory, useLocation } from "react-router";
import jsonData from "../services/data.json";
import { isBrowser, isMobile } from "react-device-detect";
import { getRegionName, haversineDistance } from "../utils/helpers";
//import Modal from "react-modal";
import Select from "react-select";
import mas from "../assets/image/mas.svg";
import comparador from "../assets/image/comparador.svg";

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ItemComparador from "../components/item-comparador";
import { useRef } from "react";
import Whatsapp from "../components/whatsapp";
import { paginate as Paginator } from "../utils/helpers";
import PaginatorControls from "../components/paginator-controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown, faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import toaster from "rsuite/toaster";
import { Message } from "rsuite";
import { region } from "caniuse-lite";

const Resultados = () => {

	const location = useLocation();
	const history = useHistory();
	const [centers, setCenters] = useState();
	const [allCenters, setAllCenters] = useState();
	const [userLocation, setUserLocation] = useState({ lat: -33.45694, lng: -70.64827 });
	const [mapItems, setMapItems] = useState([]);
	const [allMapItems, setAllMapItems] = useState([]);
	const [lastHover, setLastHover] = useState();
	const [modalPage, setModalPage] = useState(1);
	const [openModal, setOpenModal] = useState(false);
	const [lastModalPage, setLastModalPage] = useState(false);
	const [compareCenters, setCompareCenters] = useState([]);
	const [centerMapPopUp, setCenterMapPopUp] = useState([]);
	const [showMap, setShowMap] = useState(true);
	const [examTooltips, setExamTooltips] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const cardsItemRef = useRef([]);
	const pageSize = 15;
	const [centersCount, setCentersCount] = useState();
	const [paginationStart, setPaginationStart] = useState();
	const [paginationFinish, setPaginationFinish] = useState();

	useEffect(() => {
		window.scrollTo(0, 0);
		if (location.state === undefined) {
			history.push('home');
		} else {
			init(location.state.exams)
		}
	}, []);

	const init = (exams) => {
		const filter = location.state.filter;
		const address = location.state.address;
		let centers = [];
		jsonData.centers.forEach((center) => {
			let centerExamsList = [];
			let total = 0;
			center.exams.forEach((e) => {
				const centerExam = exams.find((exam) => exam !== undefined && exam.id === e);
				if (centerExam) {
					total = total + centerExam.values[center.nConvenio - 1].copay;
					centerExamsList.push(centerExam);
				}
			});
			if (centerExamsList.length > 0) {
				const { lat, lng } = center.geo;
				const distance = haversineDistance([lng, lat], [userLocation.lng, userLocation.lat], false)
				centerExamsList = Array.from(new Set(centerExamsList.map(a => a.id)))
					.map(id => {
						return centerExamsList.find(a => a.id === id)
					})
				centers.push({ center, e: centerExamsList, total, distance });
			}
			centerExamsList = [];
		});
		if (filter) {
			centers.sort((a, b) => {
				const { value } = filter;
				if (value === "closer") {
					return a.distance - b.distance;
				} else if (value === "cheaper") {
					return a.total - b.total;
				}
			});
		}
		if (address) {
			const { terms } = address.value;
			centers = centers.filter(({ center }) => {
				const search = terms[terms.length - 2].value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
				const city = center.locality.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
				const region = getRegionName(center.region).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
				return search === city || search === region;
			});
		}
		const mapMarkers = centers.map(({ center }) => ({ id: center.id, latLng: [center.geo.lat, center.geo.lng], center }));
		setAllMapItems(mapMarkers);
		setAllCenters(centers);
		setMapItems(Paginator(mapMarkers, pageSize, currentPage));
		setCenters(Paginator(centers, pageSize, currentPage));
		setExamTooltips(Paginator(exams, pageSize, currentPage));
		setCentersCount(centers.length);
		setPaginationStart(1);
		setPaginationFinish(pageSize);
	}

	const handlePaginateLeft = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	}

	useEffect(() => {
		if (allMapItems.length > 0 && allCenters.length > 0) {
			setMapItems(Paginator(allMapItems, pageSize, currentPage));
			setCenters(Paginator(allCenters, pageSize, currentPage));
			setPaginationStart((pageSize * currentPage) - (pageSize - 1));
			setPaginationFinish(pageSize * currentPage);
		}
	}, [currentPage])

	const handlePaginateRight = () => {
		if (Paginator(allMapItems, pageSize, (currentPage + 1)).length > 0) {
			setCurrentPage(currentPage + 1);
		}
	}

	useEffect(() => {
		getUserLocation();
	}, []);

	const getUserLocation = async () => {
		const { geolocation } = navigator;
		if (geolocation) {
			const { state } = await navigator.permissions.query({ name: "geolocation" });
			if (state === "granted" || state === "prompt") {
				geolocation.getCurrentPosition(successGeolocation, errorGeolocation)
			}
		} else {
			console.log("Gelocación no disponible");
		}
	}

	const successGeolocation = (position) => {
		const { latitude, longitude } = position.coords;
		setUserLocation({ lat: latitude, lng: longitude });
	}

	const errorGeolocation = (error) => {
		console.log(`error gelocation ${error}`);
	}

	const checklastPage = (array, page_number) => {
		const lastPage = array.slice(((page_number + 1) - 1) * (isMobile ? 1 : 15), (page_number + 1) * (isMobile ? 1 : 15)).length === 0;
		setLastModalPage(lastPage);
	}

	useEffect(() => {
		if (centers) {
			checklastPage(centers, modalPage)
		}
	}, [modalPage]);

	const compareChange = (evt, _centers) => {
		const mapMarkers = [_centers].map(({ center }) => ({ id: center.id, latLng: [center.geo.lat, center.geo.lng], center }));
		if (evt.target.checked) {
			setCompareCenters((old) => [...old, _centers]);
			setCenterMapPopUp([...centerMapPopUp, ...mapMarkers]);
		} else {
			const centers = compareCenters.filter((c) => c.center.id !== _centers.center.id);
			setCompareCenters([...centers]);
		}
	}

	const removeExam = (exam) => {
		const exams = examTooltips.filter(e => e.id !== exam.id);
		init(exams)
		if (exams.length <= 0) {
			setCompareCenters([])
		}
	}

	const changeOrder = ({ value }) => {
		const filteredCenters = centers.sort((a, b) => {
			if (value === "closer") {
				return a.distance - b.distance;
			} else if (value === "cheaper") {
				return a.total - b.total;
			}
		});
		setCenters([...filteredCenters]);
	}

	const removeCompareItem = (i) => {
		const items = compareCenters.filter(c => i.center.id !== c.center.id);
		if (items.length <= 1) {
			setOpenModal(false)
			checkobox()
			setCompareCenters([])
			setCenterMapPopUp([])
		} else {
			setCompareCenters(items);
		}
	}

	const handlePinCallBack = (id) => {
		setLastHover(id);
	}
	const checkobox = () => {
		let checkboxes = document.getElementsByClassName('checkbox-modal'); //obtenemos todos los controles del tipo Input
		for (let i = 0; i < checkboxes.length; i++) //recoremos todos los controles
		{
			if (checkboxes[i].type === "checkbox") //solo si es un checkbox entramos
			{
				checkboxes[i].checked = false;
			}
		}
	}
	// TODO: Mejorar lógica de modal
	var title = [{ "name": "" }, { "name": "Distancia" }, { "name": "Valoración" }, { "name": "Precio" }, { "name": "Total" }, { "name": "" }];
	return (
		<div className="main-container w-full">
			<Modal toggle={() => { setOpenModal(false) }} isOpen={openModal} backdrop={true} fade={false} size='lg'>
				<ModalHeader toggle={() => { setOpenModal(false) }}></ModalHeader>
				<ModalBody className="p-0">
					<div className="table-responsive">
						<table className="table table-bordered table-compare table-desktop m-0">
							{title.map((v, i) => (
								<tr key={i}>
									<th className="text-center">
										{v.name}
									</th>
									{compareCenters.map((value, index) => (
										<ItemComparador value={value} i={i} index={index} removeCompareItem={removeCompareItem} nConvenio={value.center.nConvenio} />
									))}
								</tr>
							))}
						</table>
					</div>
					{showMap && (
						<div style={{ height: "42vh" }}>
							<MapContainer selectedExams={location.state?.exams} pinClickCallback={setLastHover} mapsItems={centerMapPopUp} lastHover={lastHover} compareCenters={compareCenters} />
						</div>
					)}
				</ModalBody>
			</Modal>
			<Header />
			{!isMobile && (<Searcher />)}
			<div className="container-fluid g-0" style={{ backgroundColor: "#FBFBFB" }}>
				<div className="row">
					<div className={`col-12 col-md-6 ${showMap ? 'vh-20' : ''}`}>
						<div className="row d-md-none">
							<div className="col-6 movil-select" style={{ margin: 0, padding: 0, paddingLeft: 12 }}>
								<Select isDisabled={false} onChange={changeOrder} options={[{ value: "closer", label: "Cercanía" }, { value: "cheaper", label: "Mas barato" }]} placeholder="Ordenar por" />
							</div>
							<div className="col-6 p-0">
								<button style={{ backgroundColor: "#2EC3E5", color: "white", width: "100%" }} className="btn btn-primary tab-comparador" disabled={compareCenters.length < 2} onClick={() => setOpenModal(true)}>Ir al comparador <img src={comparador} className="icon-compare" alt="" /> <span className="btn-comparador" style={{ backgroundColor: "#D7373F", borderRadius: 30, paddingLeft: 5, paddingRight: 5 }}>{compareCenters.length}</span></button>
							</div>
						</div>
					</div>
				</div>
				<div className="row g-0 d-flex flex-column-reverse flex-md-row">
					<div className="col-md-6 pl-md-7">
						<div className="row m-2 my-md-3 d-none d-md-flex flex-row">
							<h5 className="m-md-0" style={{ color: "#0A46A5", width: "max-content" }}>Resultados de búsqueda</h5>
							{examTooltips.map((exam) => exam !== undefined && <span style={{ marginRight: 3 }} className="exam-tooltip">{exam?.label}
								<span
									key={Math.random()}
									onClick={() => removeExam(exam)}
									style={{
										cursor: "pointer",
										marginLeft: 7,
										color: "grey",
									}}>x</span></span>)}
						</div>
						<div className="row m-2 my-md-3 d-none d-md-flex">
							<div className="col-md-6">
								<Select isDisabled={false} onChange={changeOrder} options={[{ value: "closer", label: "Cercanía" }, { value: "cheaper", label: "Mas barato" }]} placeholder="Ordenar por" />
							</div>
							<div className="offset-md-1 col-md-5">
								<button style={{ backgroundColor: "#2EC3E5", color: "white", borderColor: "#2EC3E5" }} className="btn btn-primary" disabled={compareCenters.length < 2} onClick={() => setOpenModal(true)}>Ir al comparador <img src={comparador} className="icon-compare" alt="" /> <span className="btn-comparador" style={{ backgroundColor: "#D7373F", borderRadius: 30, paddingLeft: 5, paddingRight: 5 }}>{compareCenters.length}</span></button>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<PaginatorControls maxItems={centersCount} start={paginationStart} finish={paginationFinish} leftCallback={handlePaginateLeft} rightCalback={handlePaginateRight} />
							</div>
							<div data-bs-spy="scroll" className={`col-md-12 scroll-card ${showMap ? 'vh-4' : ''}`} style={{ overflowY: "scroll" }}>
								{
									centers !== undefined && Object.keys(centers).length > 0 ?
										(
											centers?.map((center, index) => {
												return (
													<CardExam prev={location.state?.prev} ref={(el) => cardsItemRef.current[index] = el} key={center.center.id} index={index} onCompareChange={(evt) => compareChange(evt, center)} distance={center.distance} selectedExams={location.state.exams} exams={center.e} center={center.center} total={center.total} id={center.center.id} isLastHover={center.center.id === lastHover} hoverCallback={setLastHover} />
												)
											})
										)
										:
										(
											<div className="mx-1 px-3">
												<p>No se encontraron Centros disponibles</p>
											</div>
										)
								}
							</div>
						</div>
					</div>
					<div className="col-md-6" style={{ position: "relative" }}>
						{
							showMap && (
								<div className="container-element">
									<MapContainer selectedExams={location.state?.exams} pinClickCallback={(id) => handlePinCallBack(id)} mapsItems={mapItems} lastHover={lastHover} showMap={showMap} compareCenters={compareCenters} />
								</div>
							)
						}
						{isMobile && <FontAwesomeIcon onClick={() => setShowMap(!showMap)} style={{ float: "right", marginTop: showMap ? -15 : 0, zIndex: 1, position: "relative" }} icon={showMap ? faArrowAltCircleUp : faArrowAltCircleDown} size="2x" color="#0dcaf0" />}
					</div>
				</div>
			</div>
			<Footer />
		</div >
	)
}

const Searcher = () => {

	const [examNumber, setExamNumber] = useState([0]);
	const [selectedExams, setSelectedExams] = useState([]);
	const [selectedAddress, setSelectedAddress] = useState();
	const [selectedFilter, setSelectedFilter] = useState();
	const [selectedPrevicion, setSelectedPrevicion] = useState();
	const history = useHistory();

	const selectExamCallback = (exam, position, filter, address) => {
		if (position === 0) {
			setSelectedFilter(filter);
			setSelectedAddress(address);
		}
		const examsArray = selectedExams;
		examsArray[position] = exam;
		setSelectedExams([...examsArray]);
	}

	const addExam = () => {
		let n = examNumber.length;
		n++;
		setExamNumber(new Array(n).fill(0));
	}

	const goToResults = () => {
		if (selectedExams.length > 0 && selectedPrevicion !== undefined) {
			history.push('/resultados', { exams: selectedExams, filter: selectedFilter, address: selectedAddress, prev: selectedPrevicion.value });
		} else {
			toaster.push(
				<Message type="error">
					{selectedExams.length === 0 ? "Escoja al menos un examen" : "Seleccione su previsión"}
				</Message>
			)
		}
	};

	return (
		<>
			<div className="searcher-container row py-md-3 px-md-7 mx-md-0 ">
				<div className="col-md-10" style={{ paddingRight: 0 }}>
					{
						examNumber.map((n, i) => (
							<SearchFilters
								key={i}
								isDisabled={i > 0}
								position={i}
								selectExamCallback={selectExamCallback}
								selectedFilterCallback={i === 0 ? setSelectedFilter : undefined}
								selectedAddressCallback={i === 0 ? setSelectedAddress : undefined}
								selectedPrevicionCallback={i === 0 ? setSelectedPrevicion : undefined}
							/>))
					}
				</div>
				<div className="col-md-2">
					<button style={{ backgroundColor: "#2EC3E5", color: "white", height: "100%" }} onClick={goToResults} className="btn btn-primary button-submit-resultados">Buscar</button>
				</div>
				<div className="row mt-0">
					<div className="col-md-3">
						<button className="btn btn-transparent p-0" onClick={addExam}>
							<img className="me-md-2" src={mas} alt="banner" style={{ pointerEvents: "all", width: 22 + "px" }} />
							<span style={{ color: "white", marginLeft: 3, fontSize: 14, fontWeight: 100 }}>Agregar examen</span>
						</button>
					</div>
				</div>
			</div>
			<Whatsapp />
		</>
	)
}

export default Resultados;
